import axios from 'axios';
import React, { useEffect, useState } from 'react'



const About = () => {
  const [mains, setMain] = useState([]);

  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch('pdf.pdf').then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'pdf.pdf';
            alink.click();
        })
    })
}
  const fetchData = async () => {
    const response = await axios.get("https://api.ishworineupane.com.np/main.php");
    setMain(response.data);
    console.log(response.data.name)
  }
useEffect(() => {
  fetchData();
}, [])
  return (
    
    <div>
      {mains.map((main) => (
        <section key={main.name} id="about">
        <div className="row">
          <div className="three columns">
            <img
              className="profile-pic"
              src="./images/ishu.jpg"
              alt="Ishwori Neupane"
            />
          </div>
          <div className="nine columns main-col">
            <h2>About Me</h2>

            <p>{main.bio}</p>
            <div className="row">
              <div className="columns contact-details">
                <h2>Contact Details</h2>
                <p className="address">
                  <span>{main.name}</span>
                  <br />
                  <span>
                    {main.street}
                    <br />
                    {main.city} {main.state}, {main.zip}
                  </span>
                  <br />
                  <span>{main.phone}</span>
                  <br />
                  <span>{main.email}</span>
                </p>
              </div>
              <div className="columns download">
              <button onClick={onButtonClick}>
              Download Resume
                </button>
                {/* <p>
                  <a href={main.resumeDownload} className="button">
                    <i onClick={onButtonClick} className="fa fa-download"></i>Download Resume
                  </a> 
                </p>*/}
              </div>
            </div>
          </div>
        </div>
      </section>

      )
        
       )} 
       
    
    </div>
  )
}

export default About
